var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "mt-10", attrs: { id: "contact-us-container" } },
    [
      _c(
        "v-row",
        { staticClass: "m-0 faq-hero-bg" },
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-md-6 hero-left-col order-2 order-md-1" },
            [
              _c("div", { attrs: { id: "faq-hero-text-container" } }, [
                _c("h1", { staticClass: "mt-md-16 mt-6" }, [
                  _vm._v(" " + _vm._s(_vm.$t("WeWouldLoveToHerFromYou")) + " "),
                ]),
                _c("p", { attrs: { id: "hero-subtitle" } }, [
                  _vm._v(_vm._s(_vm.$t("WeWouldLoveToHerFromYouDesc"))),
                ]),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass:
                "col-12 col-md-6 d-flex justify-md-end order-1 order-md-2 hero-right-col",
            },
            [
              _c("v-img", {
                staticClass: "mt-12 px-3",
                attrs: {
                  "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 500 : 300,
                  "lazy-src": require("../assets/images/contact-us-hero-img.webp"),
                  src: require("../assets/images/contact-us-hero-img.webp"),
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "col-12 d-flex justify-center order-3 mt-5" },
            [
              _c(
                "a",
                { attrs: { href: "#content" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "contact-us-arrow-down",
                      attrs: { width: "34", height: "34", color: "black" },
                    },
                    [_vm._v(" mdi-arrow-down-circle-outline ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mt-12", attrs: { id: "content" } }),
      _c(
        "v-row",
        { staticClass: "col-md-10 mx-md-auto", attrs: { id: "contact-cards" } },
        [
          _c("v-col", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "contact-card" }, [
              _c("p", { staticClass: "contact-card-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("ContactUsPage.Contactus.Title")) + " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("ContactUsPage.Contactus.Description")) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-action" }, [
                _c(
                  "a",
                  { attrs: { href: "mailto:tonyb@ucarmobile.com" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          outlined: "",
                          height: "56",
                          color: "#12945F",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("ContactUsPage.Contactus.Button")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("v-col", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "contact-card" }, [
              _c("p", { staticClass: "contact-card-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("ContactUsPage.PartnerShip.Title")) + " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("ContactUsPage.PartnerShip.Description")) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-action" }, [
                _c(
                  "a",
                  { attrs: { href: "mailto:tonyb@ucarmobile.com" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          outlined: "",
                          height: "56",
                          color: "#12945F",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("ContactUsPage.PartnerShip.Button")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("v-col", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "contact-card" }, [
              _c("p", { staticClass: "contact-card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("ContactUsPage.MediaInquiries.Title")) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("ContactUsPage.MediaInquiries.Description")) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-action" }, [
                _c(
                  "a",
                  { attrs: { href: "mailto:tonyb@ucarmobile.com" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          outlined: "",
                          height: "56",
                          color: "#12945F",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("ContactUsPage.MediaInquiries.Button")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("v-col", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "contact-card" }, [
              _c("p", { staticClass: "contact-card-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("ContactUsPage.BecomeMechanic.Title")) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("ContactUsPage.BecomeMechanic.Description")) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "contact-card-action" }, [
                _c(
                  "a",
                  { attrs: { href: "mailto:tonyb@ucarmobile.com" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          outlined: "",
                          height: "56",
                          color: "#12945F",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("ContactUsPage.BecomeMechanic.Button")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }